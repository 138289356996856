import {generateAssets} from 'src/shared/responsiveAssetsLoader.js';

const assetsDir = 'games/HideOut/';
const gameAssets = {
  audio: {
    theme: 'theme.mp3',
    gamePlay: 'gameplay.mp3',
    correct: 'correct.mp3',
    incorrect: 'incorrect.mp3',
    disabled: 'disabled.mp3',
    quizCorrect: 'quiz-correct.mp3',
    gameEnd: 'gameend.mp3',
    roundEnd: 'round-end.mp3'
  },
  images: {
    splash: 'splash.webp',
    background: 'wood_background.webp',
    modal: 'modal.webp',
    eyesOpen: 'eyes-open2.webp',
    eyesClosed: 'eyes-closed2.webp',
    hole: 'hole.webp',
    popup: 'question_pop-up.webp',
    score: 'score_screen.webp'
  }
};

export default generateAssets(assetsDir, gameAssets);