export default {
    gamePlay: {
        playTitle: "So, remember how this works?",
        playInstructions: "<p>Answer the yes or no questions correctly to move forward.</p><p>Correct answers get you the password. Remember them!</p> <p>You will be asked to recall them IN the ORDER you saw them at the end of each round</p>",
        playButton: 'Start!'
    },
    testTime: {
        title: "Test time!",
        body: "Which are your passwords?"
    },
    answerCorrect: {
        title: "Correct!",
        body: "Let's try another."
    },
    answerCorrectButNotInOrder: {
        title: "Correct!",
        body: "Let's keep going, answer in order for a bonus!"
    },
    answerIncorrect: {
        title: "Try again!",
        body: "Let's try another."
    },
    answerIncorrectLast: {
        title: "That's not the one!",
        body: "Let's try another next time."
    },
    levelUp: {
        title: "Amazing job, level up!!!",
        bodyRender: (level) => `Moving to level ${level}, and you got some tickets!`
    },
    rounds: {
        end: "End of Round {{round}}",
        playNext: "Play next round!",
        all: "Excellent! You got {{correct}} trials correct!",
        some: "Great job! You got {{correct}} trials correct in this round.",
        none: "Good try. Let's keep practicing!"
    },
    gameComplete: {
        playTitle: "Game Completed!",
        playInstructions: "Great work, keep practicing!",
        playButton: 'Back'
    },
    instructions: [
        {
            'title': 'First comes the questions...',
            'body': 'Answer the yes or no questions correctly to move foward.',
            'buttonText': 'Cool',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Then, pay attention, okay?',
            'body': 'Correct answers get you the password. REMEMBER THEM! You will be asked to recall then IN the ORDER you saw them at the end of each round.',
            'buttonText': 'Cool',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Ready to start?',
            'body': 'Press yes to start, no to see the instructions again.',
            'buttonText': 'Yes',
            'buttonText2': 'No',
            'buttonAction': 'goToPlay',
            'buttonAction2': 'goToBeginning'
        }
    ]
};
